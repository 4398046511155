<template>
    <div>
        <v-menu :close-on-content-click="true" offset-y>
            <template v-slot:activator="{ on }">
                <v-btn class="font-weight-bold caption mr-1 d-flex align-center" color="secondary" text outlined v-on="on">
                    <div class="d-flex align-center">
                        <v-icon class="mr-1" small>desktop_windows</v-icon>
                        Run from application
                    </div>
                </v-btn>
            </template>
            <v-card style="overflow-y: hidden" v-if="currentSpaceType === spaceTypes.VENDOR_SPACE" flat>
                <v-card-text>
                    <v-alert max-width="600" class="my-0" text prominent type="info">
                        Querying data from applications is possible after distributing the given table to a research / education space.
                    </v-alert>
                </v-card-text>
            </v-card>
            <v-list v-else>
                <div v-for="(app, index) in appTypeMetaData" :key="index">
                    <QuerySnipperDialog :connectorData="getConnectorData(app)" />
                </div>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
const QuerySnipperDialog = () => import('./TheSnapshotTableQuerySnippetsDialog.vue')
export default {
    name: 'GetTableData',
    mixins: [appTypeAndImageLink, enumsData],
    components: {
        QuerySnipperDialog
    },
    props: {
        sqlText: String
    },
    methods: {
        getConnectorData(appName) {
            if (appName === this.appTypes.JUPYTER.name) {
                return {
                    appName: 'Python',
                    appShortName: this.appTypes.JUPYTER.name,
                    language: 'python',
                    externalConfigLink: 'https://docs.nuvolos.cloud/features/database-integration/access-data-from-applications#connecting-with-python',
                    connectorLink: 'https://github.com/nuvolos-cloud/python-connector',
                    nuvolosConnector: this.pythonConnectorData.nuvolosConnector,
                    localComputerConnector: this.pythonConnectorData.localComputerConnector
                }
            } else if (appName === this.appTypes.RSTUDIO.name) {
                return {
                    appName: 'RStudio',
                    appShortName: this.appTypes.RSTUDIO.name,
                    language: 'r',
                    externalConfigLink: 'https://docs.nuvolos.cloud/features/database-integration/access-data-from-applications#connecting-with-r',
                    connectorLink: 'https://github.com/nuvolos-cloud/r-connector',
                    nuvolosConnector: this.rConnectorData.nuvolosConnector,
                    localComputerConnector: this.rConnectorData.localComputerConnector
                }
            } else if (appName === this.appTypes.STATA.name) {
                return {
                    appName: 'Stata',
                    appShortName: this.appTypes.STATA.name,
                    language: '',
                    externalConfigLink: 'https://docs.nuvolos.cloud/features/database-integration/access-data-from-applications#connecting-with-stata',
                    nuvolosConnector: this.stataConnectorData.nuvolosConnector,
                    localComputerConnector: this.stataConnectorData.localComputerConnector
                }
            } else if (appName === this.appTypes.MATLAB.name) {
                return {
                    appName: 'Matlab',
                    appShortName: this.appTypes.MATLAB.name,
                    language: 'matlab',
                    externalConfigLink: 'https://docs.nuvolos.cloud/features/database-integration/access-data-from-applications#connecting-with-matlab',
                    connector: this.matlabConnectorData,
                    nuvolosConnector: this.matlabConnectorData.nuvolosConnector,
                    localComputerConnector: this.matlabConnectorData.localComputerConnector
                }
            }
            return {}
        },
        queryTextOneLine(quoteChange) {
            if (!this.sqlText) return ''
            if (quoteChange === 'escapeDoubleQuote') return this.sqlText.replace(/\n/g, ' ').replace(/"/g, '\\"')
            if (quoteChange === 'singleToTwoSingle') return this.sqlText.replace(/\n/g, ' ').replace(/'/g, "''")
            if (quoteChange === 'noEscape') return this.sqlText.replace(/\n/g, ' ')
        }
    },
    computed: {
        ...mapState('userStore', ['snowflakeTokens']),
        ...mapState('snapshotStore', ['tablesPath']),
        ...mapGetters('spaceStore', ['currentSpaceType']),
        appTypeMetaData() {
            return [this.appTypes.JUPYTER.name, this.appTypes.RSTUDIO.name, this.appTypes.MATLAB.name, this.appTypes.STATA.name]
        },
        pythonConnectorData() {
            return {
                nuvolosConnector: `from nuvolos import get_connection\nimport pandas as pd\nconn = get_connection()\nresult_data = pd.read_sql("${this.queryTextOneLine(
                    'escapeDoubleQuote'
                )}", con=conn)`,
                localComputerConnector: `from nuvolos import get_connection\nimport pandas as pd\nconn = get_connection(dbname="${
                    this.tablesPath.db
                }", schemaname="${this.tablesPath.schema}")\nresult_data = pd.read_sql("${this.queryTextOneLine('escapeDoubleQuote')}", con=conn)`
            }
        },

        rConnectorData() {
            return {
                nuvolosConnector: `conn <- nuvolos::get_connection()\nresult_data <- dbGetQuery(conn,"${this.queryTextOneLine('escapeDoubleQuote')}")`,
                localComputerConnector: `result_data <- nuvolos::read_sql("${this.queryTextOneLine('escapeDoubleQuote')}", dbname="${
                    this.tablesPath.db
                }", schemaname="${this.tablesPath.schema}")`
            }
        },
        matlabConnectorData() {
            return {
                nuvolosConnector: `conn = get_connection();\nresult_data = select(conn,'${this.queryTextOneLine('singleToTwoSingle')}');`,
                localComputerConnector: `conn = get_connection('${this.tablesPath.db}', '${
                    this.tablesPath.schema
                }');\nresult_data = select(conn,'${this.queryTextOneLine('singleToTwoSingle')}');`
            }
        },
        stataConnectorData() {
            return {
                nuvolosConnector: `odbc load, exec(\`"${this.queryTextOneLine('noEscape')}"') connectionstring($conn_str)`,
                localComputerConnector: `odbc load, exec(\`"${this.queryTextOneLine('noEscape')}"') connectionstring($conn_str)`
            }
        }
    }
}
</script>
